import firebase from "firebase/app";
import "firebase/analytics";
import {
    Scene,
    PerspectiveCamera,
    WebGLRenderer,
    Fog,
} from 'three';
import Updater from "./classes/Updater";
import Lighting from "./classes/Lighting";
import Floor from "./classes/Floor";
import Television from "./classes/Television";
import Remote from "./scripts/Remote";
import VideoPlayer from "./classes/VideoPlayer";
import CustomCamera from "./scripts/CustomCamera";
import LinkListeners from "./scripts/LinkListeners";
import SavrStore from "./scripts/SavrStore";
import SavrArtists from "./scripts/SavrArtists";
import "./styles.css";

SA.redirection_mobile ({
    redirection_param : "mobile_redirection",
    mobile_prefix : "m",
    cookie_hours : "2",
    tablet_redirection : "true",
});

const firebaseConfig = {
    apiKey: "AIzaSyAYUT0Yu4Fy40CSGxrpJh-E2mJJ3LK4Dek",
    authDomain: "savr-tv-site.firebaseapp.com",
    projectId: "savr-tv-site",
    storageBucket: "savr-tv-site.appspot.com",
    messagingSenderId: "993252694284",
    appId: "1:993252694284:web:fb8e73c12f104587d69129",
    measurementId: "G-42KDWHXL79"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const { scene, camera, renderer } = setupScene();
const updatableArray = [];

new Lighting(scene);
new Floor(scene);
new Television(scene);

Remote(scene, camera);
LinkListeners();
SavrStore(scene, camera);
SavrArtists(scene, camera);

updatableArray.push(new VideoPlayer(scene));

new Updater(scene, camera, renderer, updatableArray);

function setupScene() {
    const scene = new Scene();
    const camera = CustomCamera();
    const renderer = new WebGLRenderer({ antialias: true, alpha: true });

    scene.fog = new Fog('purple', 1, 20)
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);

    return { scene, camera, renderer };
}
