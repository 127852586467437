import { messages } from "../messages";

export default function LinkListeners() {
    const links = Array.from(document.querySelectorAll('.links a'));

    links.forEach(link => {
        link.addEventListener('click', () => {
            onLinkClicked(link.id);
        });
    });

    function onLinkClicked(link) {
        dispatchEvent(new CustomEvent(messages.NAV_LINK_CLICKED, {
            detail: {
                link
            }
        }));
    }
}
