import { Color, FontLoader, Mesh, MeshBasicMaterial, Raycaster, TextGeometry, Vector2 } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import helvetikerBold from 'three/examples/fonts/helvetiker_bold.typeface.json';

export default function StoreItem(model, text, link, position, scene, camera) {
    const raycaster = new Raycaster();
    const mouse = new Vector2();

    let mesh = null;
    let isMouseOver = false;

    loadModel(model, position, scene);
    loadText(text, position, scene);

    function loadModel(model, position, scene) {
        const loader = new GLTFLoader();
        let { x, y, z } = position;

        loader.load(model, function (gltf) {
            gltf.scene.position.x = x;
            gltf.scene.position.y = y;
            gltf.scene.position.z = z;
            scene.add(gltf.scene);

            mesh = gltf.scene.children[0];

            addEventListener('mousemove', onMouseMove);
            addEventListener('click', onClick);
        },
            undefined,
            function (error) {
                console.error(error);
            });
    }

    function loadText(text, position, scene) {
        const loader = new FontLoader();
        const parsedFont = loader.parse(helvetikerBold);
        let { x, y, z } = position;

        const geometry = new TextGeometry(text, {
            font: parsedFont,
            size: 0.03,
            height: 0,
        });

        const material = new MeshBasicMaterial({ color: new Color(0xffffff) });
        const mesh = new Mesh(geometry, material);

        mesh.position.x = x + 0.01;
        mesh.position.y = y - 0.05;
        mesh.position.z = z;

        scene.add(mesh);
    }

    function onMouseMove(event) {
        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

        // update the picking ray with the camera and mouse position
        raycaster.setFromCamera(mouse, camera);

        // calculate objects intersecting the picking ray
        const intersects = raycaster.intersectObject(mesh);

        if (intersects.length > 0 && isMouseOver === false) {
            isMouseOver = true;
            toggleHighlight();
        } else if (intersects.length === 0 && isMouseOver === true) {
            isMouseOver = false;
            toggleHighlight();
        }
    }

    function toggleHighlight() {
        if (isMouseOver) {
            mesh.material.color.r = 2;
            mesh.material.color.g = 2;
            mesh.material.color.b = 2;
            document.body.setAttribute('style', 'cursor: pointer;');
        } else {
            mesh.material.color.r = 1;
            mesh.material.color.g = 1;
            mesh.material.color.b = 1;
            document.body.removeAttribute('style');
        }
    }

    function onClick() {
        if (isMouseOver) {
            window.open(link, '_blank');
        }
    }
}
