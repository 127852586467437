import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { messages } from '../messages';
import tvModel from '../models/savr-tv.gltf';

export default class Television {
    constructor(scene) {
        const loader = new GLTFLoader();

        loader.load(tvModel, function (gltf) {
            gltf.scene.position.y = 0
            gltf.scene.position.z = -2.2
            gltf.scene.scale.x = 2.5
            gltf.scene.scale.y = 2.5
            gltf.scene.scale.z = 2.5

            scene.add(gltf.scene)

            dispatchEvent(new Event(messages.TV_LOADED));
        },
            undefined,
            function (error) {
                console.log('error occured')
                console.error(error);
            })
    }
}
