import { Texture, LinearFilter, RGBFormat, PlaneGeometry, MeshBasicMaterial, Mesh } from 'three';
import { messages } from '../messages/index.js';
import nymphVideo from '../videos/nymph.mp4';
import oryVideo from '../videos/few-hours-with-ory-teaser.mp4';
import agarPlatesVideo from '../videos/agar-plates.mp4';

export default class VideoPlayer {
    constructor(scene) {
        this.videoElement = null;
        this.noiseVideoElement = null;
        this.texture = null;
        this.noiseTexture = null;
        this.unMuted = false;
        this.currentVideoIndex = 0;
        this.videos = [
            nymphVideo,
            oryVideo,
            agarPlatesVideo
        ];

        this.onTVLoaded = () => { this.init(scene) };
        addEventListener(messages.TV_LOADED, this.onTVLoaded);

        this.onClick = () => { this.unMute() };
        addEventListener('click', this.onClick);
    }

    init(scene) {
        this.videoElement = document.querySelector('#video');
        this.texture = new Texture(this.videoElement);
        this.texture.minFilter = LinearFilter;
        this.texture.magFilter = LinearFilter;
        this.texture.format = RGBFormat;

        this.noiseVideoElement = document.querySelector('#noise-video');
        this.noiseTexture = new Texture(this.noiseVideoElement);
        this.noiseTexture.minFilter = LinearFilter;
        this.noiseTexture.magFilter = LinearFilter;
        this.noiseTexture.format = RGBFormat;

        const size = 2.1;
        const geometry = new PlaneGeometry(1 * size, 0.8256880734 * size);
        const material = new MeshBasicMaterial({ map: this.texture });
        const mesh = new Mesh(geometry, material);

        mesh.position.z = -2.5
        mesh.position.y = 1.45
        scene.add(mesh)

        const noiseGeometry = new PlaneGeometry(1 * size, 0.8256880734 * size);
        const noiseMaterial = new MeshBasicMaterial({ map: this.noiseTexture });
        const noiseMesh = new Mesh(noiseGeometry, noiseMaterial);

        noiseMesh.position.z = -2.49;
        noiseMesh.position.y = 1.45;
        scene.add(noiseMesh);

        noiseMesh.material.transparent = true;
        noiseMesh.material.opacity = 0;

        this.setVideoCurrentTime();

        this.videoElement.ondurationchange = () => {
            this.setVideoCurrentTime();

            // show noise
            noiseMesh.material.opacity = 1;
            setTimeout(() => {
                noiseMesh.material.opacity = 0;
            }, 200);
        }

        document.querySelector('.unmute').setAttribute('style', 'display: block; cursor: pointer;');
        document.body.setAttribute('style', 'cursor: pointer;');
        removeEventListener(messages.TV_LOADED, this.onTVLoaded);
    }

    unMute() {
        if (this.unMuted) return;

        this.videoElement.muted = false;
        this.unMuted = true;

        document.querySelector('.unmute').setAttribute('style', 'display: none;');
        document.body.removeAttribute('style');

        dispatchEvent(new Event(messages.VIDEO_UNMUTED));

        removeEventListener('click', this.onClick);
        addEventListener(messages.REMOTE_CLICKED, () => { this.changeChannel() });
    }

    changeChannel() {
        if (this.currentVideoIndex === this.videos.length - 1) this.currentVideoIndex = 0
        else this.currentVideoIndex += 1

        this.videoElement.src = this.videos[this.currentVideoIndex];

        dispatchEvent(new Event(messages.CHANNEL_CHANGED));
    }

    setVideoCurrentTime() {
        const date = new Date();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const currentSecondThisHour = (minutes * 60) + seconds;
        const videoDuration = this.videoElement.duration;
        const skipToTime = currentSecondThisHour % videoDuration;

        this.videoElement.currentTime = skipToTime;
    }

    update() {
        if (!this.videoElement || !this.noiseVideoElement) return

        if (this.videoElement.readyState >= this.videoElement.HAVE_CURRENT_DATA) {
            this.texture.needsUpdate = true;
        }

        if (this.noiseVideoElement.readyState >= this.noiseVideoElement.HAVE_CURRENT_DATA) {
            this.noiseTexture.needsUpdate = true;
        }
    }
}
