import StoreItem from './StoreItem';
import mixtapeCassetteGLTF from '../models/mixtape-cassette.gltf';
import shadowCassetteGLTF from '../models/shadow-cassette.gltf';
import swellCassetteGLTF from '../models/swell-cassette.gltf';
import blackostumeMaskGLTF from '../models/blackostume-mask.gltf';
import blackostumeStickerGLTF from '../models/blackostume-sticker.gltf';
import blackostumeShirtGLTF from '../models/blackostume-shirt.gltf';
import savrStickersGLTF from '../models/savr-stickers.gltf';
import { Vector3 } from 'three';

export default function SavrStore(scene, camera) {
    StoreItem(
        shadowCassetteGLTF,
        'Darling Coils - Shadow Cassette    $13',
        'https://darlingcoils.bandcamp.com/album/shadow',
        new Vector3(-5.4, 1.4, 2),
        scene,
        camera
    );

    StoreItem(
        mixtapeCassetteGLTF,
        'SAVR mixtape volume 1                      $20',
        'https://savrmusic.bandcamp.com/album/mixtape-1',
        new Vector3(-4.4, 1.4, 2),
        scene,
        camera
    );

    StoreItem(
        swellCassetteGLTF,
        'HNY WLSN - Swell Cassette                 $9',
        'https://hnywlsn.bandcamp.com/album/swell',
        new Vector3(-3.4, 1.4, 2),
        scene,
        camera
    );

    StoreItem(
        blackostumeMaskGLTF,
        'BlacKostume - Face Mask  $14.99',
        'https://teespring.com/blackostume-classic-mask?tsmac=store&tsmic=blackostume&pid=972&cid=103974',
        new Vector3(-5.5, 0.6, 2),
        scene,
        camera
    );

    StoreItem(
        blackostumeStickerGLTF,
        'BlacKostume - Sticker         $3.99',
        'https://teespring.com/blackostume-classic?tsmac=store&tsmic=blackostume&pid=663&cid=102921',
        new Vector3(-4.75, 0.6, 2),
        scene,
        camera
    );

    StoreItem(
        blackostumeShirtGLTF,
        'BlacKostume - T-Shirt  $21.99',
        'https://teespring.com/blackostume-classic?tsmac=store&tsmic=blackostume&pid=2&cid=2397',
        new Vector3(-3.95, 0.6, 2),
        scene,
        camera
    );

    StoreItem(
        savrStickersGLTF,
        'SAVR Stickers           $8',
        'https://savrmusic.bandcamp.com/merch/savr-stickers',
        new Vector3(-3.25, 0.6, 2),
        scene,
        camera
    );
}
