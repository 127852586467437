import { Color, Mesh, MeshBasicMaterial, PlaneGeometry } from "three";

export default class Floor {
    constructor(scene) {
        const geometry = new PlaneGeometry(200, 200, 50, 50);
        const material = new MeshBasicMaterial({ wireframe: true, color: new Color(0x00ff00) });
        const mesh = new Mesh(geometry, material)
        mesh.receiveShadow = true;
        mesh.rotation.x = Math.PI / -2
        scene.add(mesh)
    }
}
