import { Vector3 } from 'three';
import Artist from './Artist';
import blackostumeGLTF from '../models/artist-blackostume.gltf';
import counterfitGLTF from '../models/artist-counterfit.gltf';
import darlingcoilsGLTF from '../models/artist-darlingcoils.gltf';
import hnywlsnGLTF from '../models/artist-hnywlsn.gltf';
import thePhantomFriendsGLTF from '../models/artist-the-phantom-friends.gltf';
import { messages } from '../messages';

const blackostume = {
    id: 'blackostume',
    model: blackostumeGLTF,
    name: 'BlacKostume',
    location: 'Brooklyn, NYC',
    bio: 'Producer, Lyricist, Songwriter from NC.',
    link: 'https://open.spotify.com/artist/73dSUn3RQRuJLCGDD0nEri?si=hssK-tWhTaeTeG4_XtUD7Q&dl_branch=1',
};

const counterfit = {
    id: 'counterfit',
    model: counterfitGLTF,
    name: 'Counterfit',
    location: 'Charlotte, NC // Passaic, NJ',
    bio: `Counterfit is a collaboration between
Darling Coils and Savr Dave. The music
is a industrial-post wave bond that has
a new album on the horizon in 2021.
Influences include: ESPRIT,
Throbbing Gristle, NIN, The Mars Volta,
Tears For Fears, J Dilla and Toro Y Moi...
to name a few.`,
    link: 'https://soundcloud.com/counter-fit',
};

const darlingCoils = {
    id: 'darling-coils',
    model: darlingcoilsGLTF,
    name: 'Darling Coils',
    location: 'Passaic, NJ',
    bio: `Producer, singer, songwriter from NC.
Formerly known as Def Hazy.
Former member of Lucid Colony,
Diluted Giants, and Happy White
People with Money. Also, current
member of Counterfit and The Kooksters.`,
    link: 'https://open.spotify.com/artist/2zjXg5cNcifEBB32PQ4kFH?si=4NMqziGpTuCuuR0T5ioUqw&dl_branch=1',
};

const hnywlsn = {
    id: 'hny-wlsn',
    model: hnywlsnGLTF,
    name: 'HNY WLSN',
    location: 'Charlotte, NC',
    bio: `DIY synth, funk, and wave music
out of Charlotte.`,
    link: 'https://open.spotify.com/artist/4ETDDOPGq8RXskWQNqdv5R?si=hR0ac30mSjiQIp2JtsNcMA&dl_branch=1',
};

const thePhantomFriends = {
    id: 'the-phantom-friends',
    model: thePhantomFriendsGLTF,
    name: 'The Phantom Friends',
    location: 'Charlotte, NC',
    bio: `The Phantom Friends is a Charlotte, NC
rock band formed in 2016. With a
background in alt-rock, jazz, and other
progressive influences, they play music
that is just familiar enough to sink your
teeth into and just weird enough to be
something different and new.`,
    link: 'https://open.spotify.com/artist/4W3h8K4Nf1R8nRD2M9D60O?si=PEz7wunCT9OugVOY_t6jOw&dl_branch=1',
};

const allArtists = [blackostume, counterfit, darlingCoils, hnywlsn, thePhantomFriends];

export default function SavrArtists(scene, camera) {
    allArtists.forEach(artist => {
        Artist(artist, scene, camera );
    })
}
