import { Easing, Tween } from "@tweenjs/tween.js";
import { PerspectiveCamera, Vector2, Vector3 } from "three";
import { messages } from '../messages/index.js';

export default function CustomCamera() {
    const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const mouse = new Vector2();
    const tvPosition = new Vector3(0, 1, 0);
    const storePosition = new Vector3(-4, 1, 4);
    const artistPosition = new Vector3(3, 1, 4);

    init();

    function init() {
        camera.position.x = tvPosition.x;
        camera.position.y = tvPosition.y;
        camera.position.z = tvPosition.z;

        addEventListener('mousemove', onMouseMove);
        addEventListener(messages.NAV_LINK_CLICKED, onNavLinkClicked);
    }

    function onMouseMove(event) {
        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

        rotateCamera();
    }

    function onNavLinkClicked(event) {
        const { link } = event.detail;

        switch (link) {
            case 'logo':
            case 'tv':
                moveToPosition(tvPosition);
                break;
            case 'store':
                moveToPosition(storePosition);
                break;
            case 'blackostume':
            case 'counterfit':
            case 'darling-coils':
            case 'hny-wlsn':
            case 'the-phantom-friends':
                moveToPosition(artistPosition);
                break;
            default:
                break;
        }
    }

    function moveToTV() {
        console.log('moving to tv')
    }

    function moveToPosition(targetPosition) {
        const { x, y, z } = camera.position;
        let position = new Vector3(x, y, z);
        let tween = new Tween(position).to(targetPosition, 1000);

        tween.easing(Easing.Cubic.Out);

        tween.onUpdate(() => {
            camera.position.x = position.x;
            camera.position.y = position.y;
            camera.position.z = position.z;
        });

        tween.start();
    }

    function rotateCamera() {
        camera.rotation.y = -mouse.x * 0.25;
        camera.rotation.x = mouse.y * 0.25;
    }

    return camera;
}
