import TWEEN from '@tweenjs/tween.js';
import { messages } from '../messages/index.js'

export default class Updater {
    constructor(scene, camera, renderer, updatableArray) {
        renderer.setAnimationLoop(function () {
            updatableArray.forEach(updatable => {
                updatable.update();
            });

            TWEEN.update();

            renderer.render(scene, camera);
        });
    }
}
