import { Color, FontLoader, Group, Mesh, MeshBasicMaterial, Raycaster, TextGeometry, Vector2, Vector3 } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import helvetikerBold from 'three/examples/fonts/helvetiker_bold.typeface.json';
import { Tween, Easing } from '@tweenjs/tween.js';
import { messages } from '../messages';

export default function Artist(artistData, scene, camera) {
    const { id, model, name, location, bio, link } = artistData;
    const position = new Vector3(2.7, 0.6, 2.5);
    const raycaster = new Raycaster();
    const mouse = new Vector2();
    const animationGroup = new Group();
    const activePosition = new Vector3();
    const inactivePosition = new Vector3(5, 0, 0);

    let mesh = null;
    let isMouseOver = false;

    animationGroup.position.x = inactivePosition.x;

    scene.add(animationGroup);

    addEventListener(messages.NAV_LINK_CLICKED, onNavLinkClicked);

    loadModel(model, position, scene);
    loadText(name, position, {x: 0, y: 1}, 0.08, scene);
    loadText(location, position, {x: 0, y: 0.93}, 0.03, scene);
    loadText(bio, position, {x: 0, y: 0.8}, 0.03, scene);

    function loadModel(model, position, scene) {
        const loader = new GLTFLoader();
        let { x, y, z } = position;

        loader.load(model, function (gltf) {
            gltf.scene.position.x = x + 0.85;
            gltf.scene.position.y = y;
            gltf.scene.position.z = z;
            gltf.scene.rotation.y = -0.25;
            scene.add(gltf.scene);
            animationGroup.add(gltf.scene)

            mesh = gltf.scene.children[0];


            addEventListener('mousemove', onMouseMove);
            addEventListener('click', onClick);
        },
            undefined,
            function (error) {
                console.error(error);
            });
    }

    function loadText(text, position, offset, size, scene) {
        const loader = new FontLoader();
        const parsedFont = loader.parse(helvetikerBold);
        let { x, y, z } = position;

        const geometry = new TextGeometry(text, {
            font: parsedFont,
            size,
            height: 0,
        });

        const material = new MeshBasicMaterial({ color: new Color(0xffffff) });
        const mesh = new Mesh(geometry, material);

        mesh.position.x = x + offset.x;
        mesh.position.y = y + offset.y;
        mesh.position.z = z;

        scene.add(mesh);
        animationGroup.add(mesh)
    }

    function onMouseMove(event) {
        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

        // update the picking ray with the camera and mouse position
        raycaster.setFromCamera(mouse, camera);

        // calculate objects intersecting the picking ray
        const intersects = raycaster.intersectObject(mesh);

        if (intersects.length > 0 && isMouseOver === false) {
            isMouseOver = true;
            toggleHighlight();
        } else if (intersects.length === 0 && isMouseOver === true) {
            isMouseOver = false;
            toggleHighlight();
        }
    }

    function toggleHighlight() {
        if (isMouseOver) {
            mesh.material.color.r = 2;
            mesh.material.color.g = 2;
            mesh.material.color.b = 2;
            document.body.setAttribute('style', 'cursor: pointer;');
        } else {
            mesh.material.color.r = 1;
            mesh.material.color.g = 1;
            mesh.material.color.b = 1;
            document.body.removeAttribute('style');
        }
    }

    function onClick() {
        if (isMouseOver) {
            window.open(link, '_blank');
        }
    }

    function onNavLinkClicked(event) {
        const { link } = event.detail;

        if (link === id) {
            moveToPosition(activePosition);
        } else {
            moveToPosition(inactivePosition);
        }
    }

    function moveToPosition(targetPosition) {
        const { x, y, z } = animationGroup.position;
        let position = new Vector3(x, y, z);
        let tween = new Tween(position).to(targetPosition, 1000);

        tween.easing(Easing.Cubic.Out);

        tween.onUpdate(() => {
            animationGroup.position.x = position.x;
            animationGroup.position.y = position.y;
            animationGroup.position.z = position.z;
        });

        tween.start();
    }
}
