import { Color, PointLight } from "three"

export default class Lighting {
    constructor(scene) {
        let color = new Color(255, 255, 255)
        let intensity = 0.02
        let distance = 10
        let light = new PointLight(color, intensity, distance)

        light.position.z = 4
        light.position.y = 3.5
        scene.add(light)

        let storeLight = new PointLight(color, intensity, distance);
        storeLight.position.x = -4
        storeLight.position.y = 1
        storeLight.position.z = 11
        scene.add(storeLight);

        let artistLight = new PointLight(color, 0.01, distance);
        artistLight.position.x = 4
        artistLight.position.y = 1
        artistLight.position.z = 10
        scene.add(artistLight);
    }
}
