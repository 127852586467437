import { Raycaster, Vector2, Vector3 } from 'three';
import { Easing, Tween } from '@tweenjs/tween.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { messages } from "../messages";
import remoteGLTF from '../models/savr-remote.gltf';

export default function Remote(scene, camera) {
    const raycaster = new Raycaster();
    const mouse = new Vector2();

    let remoteMesh = null;
    let isMouseOver = false;
    let canChangeChannels = true;

    let playAnimationChangeChannel = null;

    addEventListener(messages.VIDEO_UNMUTED, init);

    function init() {
        const loader = new GLTFLoader();

        loader.load(remoteGLTF, function (gltf) {
            gltf.scene.position.x = 0.85;
            gltf.scene.position.y = 0.5;
            gltf.scene.position.z = -1.1;
            gltf.scene.rotation.x = 1.1;

            scene.add(gltf.scene);
            remoteMesh = gltf.scene;

            let hitbox = remoteMesh.children[0];
            hitbox.material.opacity = 0;

            playAnimationIntro(remoteMesh);

            playAnimationChangeChannel = setupAnimationChangeChannel(remoteMesh);

            addEventListener('mousemove', onMouseMove);
            addEventListener('mousedown', changeChannels);
        },
            undefined,
            function (error) {
                console.error(error);
            })

        removeEventListener(messages.VIDEO_UNMUTED, init);
    }

    function changeChannels() {
        if (isMouseOver && canChangeChannels) {
            canChangeChannels = false;
            playAnimationChangeChannel();
            dispatchEvent(new Event(messages.REMOTE_CLICKED));
        }
    }

    function onMouseMove(event) {
        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

        // update the picking ray with the camera and mouse position
        raycaster.setFromCamera(mouse, camera);

        // calculate objects intersecting the picking ray
        const intersects = raycaster.intersectObject(remoteMesh.children[0]);

        if (intersects.length > 0 && isMouseOver === false) {
            isMouseOver = true;
            toggleRemoteHighlight();
        } else if (intersects.length === 0 && isMouseOver === true) {
            isMouseOver = false;
            toggleRemoteHighlight();
        }
    }

    function toggleRemoteHighlight() {
        let remote = remoteMesh.children[1];

        if (isMouseOver) {
            remote.material.color.r = 2;
            remote.material.color.g = 2;
            remote.material.color.b = 2;
            document.body.setAttribute('style', 'cursor: pointer;');
        } else {
            remote.material.color.r = 1;
            remote.material.color.g = 1;
            remote.material.color.b = 1;
            document.body.removeAttribute('style');
        }
    }

    function playAnimationIntro(remoteMesh) {
        let position = new Vector3(remoteMesh.position.x, remoteMesh.position.y - 1, remoteMesh.position.z);
        let target = new Vector3(remoteMesh.position.x, remoteMesh.position.y, remoteMesh.position.z);

        let moveUp = new Tween(position).to(target, 500);

        moveUp.easing(Easing.Cubic.Out);

        moveUp.onUpdate(() => {
            remoteMesh.position.x = position.x;
            remoteMesh.position.y = position.y;
            remoteMesh.position.z = position.z;
        });

        moveUp.start();
    }

    function setupAnimationChangeChannel(remoteMesh) {
        let position = new Vector3(remoteMesh.position.x, remoteMesh.position.y, remoteMesh.position.z);
        let start = new Vector3(remoteMesh.position.x, remoteMesh.position.y, remoteMesh.position.z);
        let end = new Vector3(position.x, position.y, position.z - 0.1);

        let rotation = new Vector3(remoteMesh.rotation.x, remoteMesh.rotation.y, remoteMesh.rotation.z);
        let startRotation = new Vector3(remoteMesh.rotation.x, remoteMesh.rotation.y, remoteMesh.rotation.z);
        let endRotation = new Vector3(remoteMesh.rotation.x - 0.25, remoteMesh.rotation.y, remoteMesh.rotation.z + 0.1);

        let moveForward = new Tween(position).to(end, 50);
        let moveBackward = new Tween(position).to(start, 500);

        let rotateForward = new Tween(rotation).to(endRotation, 50);
        let rotateBackward = new Tween(rotation).to(startRotation, 500);

        moveBackward.easing(Easing.Cubic.Out);
        rotateBackward.easing(Easing.Cubic.Out);

        rotateForward.delay(20);

        moveForward.chain(moveBackward);
        rotateForward.chain(rotateBackward);

        function updatePosition() {
            remoteMesh.position.x = position.x;
            remoteMesh.position.y = position.y;
            remoteMesh.position.z = position.z;
        };

        function updateRotation() {
            remoteMesh.rotation.x = rotation.x;
            remoteMesh.rotation.y = rotation.y;
            remoteMesh.rotation.z = rotation.z;
        };

        moveForward.onUpdate(updatePosition);
        moveBackward.onUpdate(updatePosition);
        rotateForward.onUpdate(updateRotation);
        rotateBackward.onUpdate(updateRotation);

        moveBackward.onComplete(() => {
            canChangeChannels = true;
        })

        return () => {
            moveForward.start();
            rotateForward.start();
        }
    }
}
